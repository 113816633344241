import React from 'react';
import {Link} from 'react-router-dom';

const url = process.env.PUBLIC_URL;

function FooterTop() {
  return (
    <div className="container">
      <div className="site-footer__top">
        <div className="footer-widget__logo">
          <Link to={`${url}/`}>
            <img src={require('@assets/images/logo-1.png')} alt="" />
          </Link>
        </div>
        <div className="footer-widget__subscribe-box">
          <div className="footer-widget__subscribe-text">
            <p>Subscribe to Newsletter</p>
          </div>
          <form className="footer-widget__email-box mc-form" data-url="#">
            <div className="footer-widget__email-input-box">
              <input type="email" placeholder="Email Address" name="EMAIL" />
            </div>
            <button type="submit" className="footer-widget__subscribe-btn thm-btn">
              Subscribe
            </button>
          </form>
          <div className="mc-form__response"></div>
        </div>
      </div>
      <div className="site-footer__middle">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms">
            <div className="footer-widget__column footer-widget__Contact">
              <div className="footer-widget__title-box">
                <h3 className="footer-widget__title">Contact</h3>
              </div>
              <p className="footer-widget__Contact-text">
                Taraba State Government ministry of information and Reorientation <br /> Taraba, Nigeria
              </p>
              <ul className="footer-widget__Contact-list list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-email"></span>
                  </div>
                  <div className="text">
                    <p>
                      <a href="mailto:info@tarabastate.gov.ng">info@tarabastate.gov.ng</a>
                    </p>
                  </div>
                </li>
              </ul>
              <div className="site-footer__social">
                <a href="https://x.com/OfficialTaraba?t=lZSYqTEwGqNdkzDrN1lbHQ&s=09">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.facebook.com/share/p/6xCq9DF2JA64yzVA/">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href={url}>
                  <i className="fab fa-youtube"></i>
                </a>
                <a href={url}>
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 wow fadeInUp" data-wow-delay="200ms">
            <div className="footer-widget__column footer-widget__link">
              <div className="footer-widget__title-box">
                <h3 className="footer-widget__title">Links</h3>
              </div>
              <ul className="footer-widget__link-list list-unstyled">
                <li>
                  <a href={url}>About</a>
                </li>
                <li>
                  <a href={url}>Upcoming Events</a>
                </li>
                <li>
                  <a href={url}>Latest News</a>
                </li>
                <li>
                  <a href={url}>Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="300ms">
            <div className="footer-widget__column footer-widget__departments">
              <div className="footer-widget__title-box">
                <h3 className="footer-widget__title">Departments</h3>
              </div>
              <ul className="footer-widget__link-list list-unstyled">
                <li>
                  <a href={url}>Health</a>
                </li>
                <li>
                  <a href={url}>Housing & Land</a>
                </li>
                <li>
                  <a href={url}>Education</a>
                </li>
                <li>
                  <a href={url}>Transport & Traffic</a>
                </li>
                <li>
                  <a href={url}>Arts & Culture</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="400ms">
            <div className="footer-widget__column footer-widget__explore">
              <div className="footer-widget__title-box">
                <h3 className="footer-widget__title">Explore</h3>
              </div>
              <ul className="footer-widget__link-list list-unstyled">
                <li>
                  <a href={url}>History of Taraba</a>
                </li>
                <li>
                  <a href={url}>Culture & Heritage</a>
                </li>
                <li>
                  <a href={url}>Tourism</a>
                </li>
                <li>
                  <a href={url}>Investment Opportunities</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="500ms">
            <div className="footer-widget__column footer-widget__gallery">
              <div className="footer-widget__title-box">
                <h3 className="footer-widget__title">Gallery</h3>
              </div>
              <ul className="footer-widget__gallery-list list-unstyled clearfix">
                <li>
                  <div className="footer-widget__gallery-img">
                    <img src={require('@assets/images/gov/IMG-20240528-WA0397.jpg')} alt="" />
                    <a target="_blank" href={require('@assets/images/gov/IMG-20240528-WA0397.jpg')} className="img-popup" rel="noreferrer">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="footer-widget__gallery-img">
                    <img src={require('@assets/images/gov/IMG-20240528-WA0314.jpg')} alt="" />
                    <a target="_blank" href={require('@assets/images/gov/IMG-20240528-WA0314.jpg')} className="img-popup" rel="noreferrer">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="footer-widget__gallery-img">
                    <img src={require('@assets/images/gov/IMG-20240530-WA0099.jpg')} alt="" />
                    <a target="_blank" href={require('@assets/images/gov/IMG-20240530-WA0099.jpg')} className="img-popup" rel="noreferrer">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="footer-widget__gallery-img">
                    <img src={require('@assets/images/gov/IMG-20240530-WA0294.jpg')} alt="" />
                    <a target="_blank" href={require('@assets/images/gov/IMG-20240530-WA0294.jpg')} className="img-popup" rel="noreferrer">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="footer-widget__gallery-img">
                    <img src={require('@assets/images/gov/IMG-20240528-WA0316.jpg')} alt="" />
                    <a target="_blank" href={require('@assets/images/gov/IMG-20240528-WA0316.jpg')} className="img-popup" rel="noreferrer">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="footer-widget__gallery-img">
                    <img src={require('@assets/images/gov/IMG-20240528-WA0400.jpg')} alt="" />
                    <a target="_blank" href={require('@assets/images/gov/IMG-20240528-WA0400.jpg')} className="img-popup" rel="noreferrer">
                      <span className="fab fa-instagram"></span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterTop;
