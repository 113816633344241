import CoreUtil from '@core/utils/core.util';
import {FETCH_LOCAL_NEWS, LOCAL_NEWS_API} from '@landing/constants';
import {HttpResponseType} from 'app-core';
import {NewsDto} from 'app-landing';

export const getLocalNewsAction = CoreUtil.asyncThunkWrapper<HttpResponseType<NewsDto>, void>(FETCH_LOCAL_NEWS, async (_, axiosClient) => {
  const client = axiosClient({baseURL: LOCAL_NEWS_API, token: false});
  const response = await client.get('');
  return response.data;
});
