import React, {lazy} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import landingRoute from '@core/routes/landing.route';
import ErrorBoundary from '@common/container/ErrorBoundary';

const PublicLayout = lazy(() => import('../../common/container/layouts/PublicLayout'));
const Error404Page = lazy(() => import('../../common/pages/Error404Page'));

export function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        {landingRoute.map(route => (
          <Route
            path={route.path}
            key={route.name}
            element={
              <PublicLayout>
                <ErrorBoundary>{route.Element}</ErrorBoundary>
              </PublicLayout>
            }
          />
        ))}
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </BrowserRouter>
  );
}
