import React, {ErrorInfo, ReactNode} from 'react';
import {IComponentErrorState} from 'app-common';
import ErrorPage from '@common/pages/ErrorPage';

interface Props {
  children?: ReactNode;
}

export default class ErrorBoundary extends React.Component<Props, IComponentErrorState> {
  public state: IComponentErrorState = {
    hasError: false,
    errorMessage: '',
    errorCode: 500,
  };

  public static getDerivedStateFromError(error: Error): IComponentErrorState {
    console.log(error);
    return {hasError: true, errorMessage: error.message, errorCode: 500};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error!:', error, errorInfo.componentStack);
    this.setState({hasError: true, errorMessage: error.message, errorCode: this.state.errorCode});
  }

  public render() {
    return this.state.hasError ? <ErrorPage /> : this.props.children;
  }
}
