import {EnvConfigType} from 'app-core';
import Joi from 'joi';

console.log(process.env);

const env: EnvConfigType = {
  development: {
    api: {root: process.env.REACT_APP_DEV_API_ROOT, baseURL: process.env.REACT_APP_DEV_API_BASE_URL},
  },
  production: {
    api: {root: process.env.REACT_APP_API_ROOT, baseURL: process.env.REACT_APP_API_BASE_URL},
  },
};

const {value, error} = Joi.object<EnvConfigType>({
  development: {
    api: {
      root: Joi.string().required(),
      baseURL: Joi.string()
        .uri({scheme: ['http', 'https']})
        .required(),
    },
  },
  production: {
    api: {
      root: Joi.string().required(),
      baseURL: Joi.string()
        .uri({scheme: ['http', 'https']})
        .required(),
    },
  },
}).validate(env);
if (error) throw new Error(error.details[0].message);

export default value as EnvConfigType;
