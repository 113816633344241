import React from 'react';
import {Link} from 'react-router-dom';

function FooterBottom() {
  return (
    <div className="site-footer__bottom">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="site-footer__bottom-inner">
              <p className="site-footer__bottom-text">
                © Copyright {new Date().getFullYear()} by <Link to="/">Taraba State Government</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
