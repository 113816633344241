import React from 'react';
import {useNavigate} from 'react-router-dom';
import AppPage from '@common/pages/AppPage';

export default function Error500Page() {
  const navigate = useNavigate();
  return (
    <AppPage title="500 Internal Server Error" sx={{height: 1}}>
      <div>
        <div>
          <div style={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
            <h3>500 Internal Server Error</h3>
            <p style={{color: 'text.secondary'}}>There was an error, please try again later.</p>

            {/**Image Here**/}

            <button onClick={() => navigate(-1)}>Go to Home</button>
          </div>
        </div>
      </div>
    </AppPage>
  );
}
