import {combineReducers, UnknownAction} from '@reduxjs/toolkit';
import smokeScreenReducer from '@common/reducers/smoke-screen.reducer';
import newsReducer from '@landing/reducers/news.reducer';

const reducers = combineReducers({
  smokeScreenReducer,
  newsReducer,
});

const rootReducer = (state: ReturnType<typeof reducers> | undefined, action: UnknownAction) => {
  if (action.type === 'authentication/resetStore') {
    return reducers(undefined, action as never);
  }

  return reducers(state, action as never);
};

export default rootReducer;
