import React, {useEffect, useState} from 'react';
import {AppRoute} from '@core/routes';
import {AppContext} from '@common/context';

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '/assets/js/app.js';
    script.async = true;

    document.body.appendChild(script);
    
    setLoading(false);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AppContext.Provider value={{loading, setLoading}}>
      <AppRoute />
    </AppContext.Provider>
  );
}

export default App;
