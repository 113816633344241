import React from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';

const url = process.env.PUBLIC_URL;

function MobileNav() {
  const toggleMobileMenu = () => {
    $('.mobile-nav__toggler').on('click', function (e) {
      e.preventDefault();
      $('.mobile-nav__wrapper').toggleClass('expanded');
      $('body').toggleClass('locked');
    });
  };

  return (
    <div className="mobile-nav__wrapper">
      <div className="mobile-nav__overlay mobile-nav__toggler"></div>
      <div className="mobile-nav__content">
        <span onClick={toggleMobileMenu} className="mobile-nav__close mobile-nav__toggler">
          <i className="fa fa-times"></i>
        </span>

        <div className="logo-box">
          <a href={url} aria-label="logo image">
            <img src={require('@assets/images/logo-1.png')} width="94" alt="" />
          </a>
        </div>
        <div className="mobile-nav__container"></div>

        <ul className="mobile-nav__contact list-unstyled">
          <li>
            <i className="fa fa-envelope"></i>
            <a href="mailto:info@tarabastate.gov.ng">info@tarabastate.gov.ng</a>
          </li>
        </ul>
        <div className="mobile-nav__top">
          <div className="mobile-nav__social">
            <Link to="https://x.com/OfficialTaraba?t=lZSYqTEwGqNdkzDrN1lbHQ&s=09" className="fab fa-twitter"></Link>
            <Link to="https://www.facebook.com/share/p/6xCq9DF2JA64yzVA/" className="fab fa-facebook-square"></Link>
            <Link to={url} className="fab fa-youtube"></Link>
            <Link to={url} className="fab fa-instagram"></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
