import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

import '@assets/vendors/bootstrap/css/bootstrap.min.css';
import '@assets/vendors/animate/animate.min.css';
import '@assets/vendors/animate/custom-animate.css';
import '@assets/vendors/fontawesome/css/all.min.css';
import '@assets/vendors/jarallax/jarallax.css';
import '@assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css';
import '@assets/vendors/nouislider/nouislider.min.css';
import '@assets/vendors/nouislider/nouislider.pips.css';
import '@assets/vendors/odometer/odometer.min.css';
import '@assets/vendors/swiper/swiper.min.css';
import '@assets/vendors/govity-icons/style.css';
import '@assets/vendors/tiny-slider/tiny-slider.min.css';
import '@assets/vendors/reey-font/stylesheet.css';
import '@assets/vendors/owl-carousel/owl.carousel.min.css';
import '@assets/vendors/owl-carousel/owl.theme.default.min.css';
import '@assets/vendors/bxslider/jquery.bxslider.css';
import '@assets/vendors/bootstrap-select/css/bootstrap-select.min.css';
import '@assets/vendors/vegas/vegas.min.css';
import '@assets/vendors/jquery-ui/jquery-ui.css';
import '@assets/vendors/timepicker/timePicker.css';
import '@assets/vendors/nice-select/nice-select.css';
import '@assets/css/govity.css';
import '@assets/css/govity-responsive.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import reportWebVitals from './reportWebVitals';
import App from './App';
import {HelmetProvider} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import store, {persistedStore} from '@core/store';
import Loader from '@common/components/loader/Loader';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistedStore} loading={<Loader showLoader />}>
          <Suspense fallback={<Loader showLoader />}>
            <App />
          </Suspense>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
