import {ThunkActionApiStateType} from 'app-core';
import {NewsDto} from 'app-landing';
import {createSlice} from '@reduxjs/toolkit';
import {getLocalNewsAction} from '@landing/actions/news.action';

interface NewsState {
  fetchLocalNewsStatus: ThunkActionApiStateType;
  fetchLocalNewsSuccess?: string;
  fetchLocalNewsError?: string;

  localNews: NewsDto[];
}

const initialState: NewsState = {
  fetchLocalNewsStatus: 'idle',
  localNews: [],
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    resetFetchLocalNewsState(state) {
      state.fetchLocalNewsStatus = 'idle';
      state.fetchLocalNewsError = '';
      state.fetchLocalNewsSuccess = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLocalNewsAction.pending, state => {
        state.fetchLocalNewsStatus = 'loading';
      })
      .addCase(getLocalNewsAction.fulfilled, (state, action) => {
        state.fetchLocalNewsStatus = 'completed';
        state.fetchLocalNewsSuccess = action.payload.message;
        state.localNews = action.payload.results;
      })
      .addCase(getLocalNewsAction.rejected, (state, action) => {
        state.fetchLocalNewsStatus = 'completed';
        state.fetchLocalNewsError = action?.payload ? action.payload.message : action.error.message;
      });
  },
});

export const {resetFetchLocalNewsState} = slice.actions;

export default slice.reducer;
