import {createAsyncThunk} from '@reduxjs/toolkit';

import {Axios} from 'axios';
import {AxiosClientOptionType, ThunkApiRejectValueType} from 'app-core';
import {axiosClient} from '@core/config';

export default class CoreUtil {
  public static asyncThunkWrapper<Returned, Args>(
    prefix: string,
    handler: (args: Args, axiosClient: (arg?: AxiosClientOptionType) => Axios, thunkAPI?: any) => Promise<any>,
  ) {
    return createAsyncThunk<
      Returned,
      Args,
      {
        rejectValue: ThunkApiRejectValueType;
      }
    >(prefix, async (args, thunkAPI) => {
      try {
        return await handler(args, axiosClient, thunkAPI);
      } catch (e: any) {
        if (e.response) return thunkAPI.rejectWithValue(e.response.data);
        return Promise.reject(e);
      }
    });
  }

  public static unfreeze<T>(object: T) {
    return JSON.parse(JSON.stringify(object)) as T;
  }
}
