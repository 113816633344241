import React, {lazy} from 'react';
import {IRoute} from 'app-core';

const ErrorPage = lazy(() => import('@common/pages/ErrorPage'));
const LandingPage = lazy(() => import('@landing/pages/LandingPage'));
const WelcomePage = lazy(() => import('@landing/pages/WelcomePage'));
const GalleryPage = lazy(() => import('@landing/pages/GalleryPage'));
const PressReleasePage = lazy(() => import('@landing/pages/PressReleasePage'));

const landingRoute: IRoute[] = [
  {
    name: 'Landing',
    path: '/',
    type: 'public',
    Element: <LandingPage />,
  },
  {
    name: 'Welcome',
    path: '/welcome',
    type: 'public',
    Element: <WelcomePage />,
  },
  {
    name: 'Gallery',
    path: '/gallery',
    type: 'public',
    Element: <GalleryPage />,
  },
  {
    name: 'Press Release',
    path: '/press-releases',
    type: 'public',
    Element: <PressReleasePage />,
  },
  {
    name: 'Error',
    path: '/error',
    type: 'public',
    Element: <ErrorPage />,
  },
];

export default landingRoute;
