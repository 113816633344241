import React from 'react';
import FooterTop from '@landing/components/layout/footer/FooterTop';
import FooterBottom from '@landing/components/layout/footer/FooterBottom';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="site-footer__img">
        <img src="../../../../../assets/images/resources/site-footer-img.jpg" alt="" />
      </div>
      <FooterTop />
      <FooterBottom />
    </footer>
  );
}

export default Footer;
