import React from 'react';

type PropTypes = {
  showLoader: boolean;
};
export default function Loader({showLoader}: PropTypes) {
  if (!showLoader) return null;
  return (
    <div className="preloader">
      <div className="preloader__image"></div>
    </div>
  );
}
