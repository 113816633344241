import React, {Fragment} from 'react';
import MainHeader from '@landing/components/layout/header/MainHeader';
import HeaderSticky from '@landing/components/layout/header/HeaderSticky';

function Header() {
  return (
    <Fragment>
      <MainHeader />
      <HeaderSticky />
    </Fragment>
  );
}

export default Header;
