import {createSlice} from '@reduxjs/toolkit';

interface ISmokeScreenState {
  message: string;
}

const initialState: ISmokeScreenState = {
  message: '',
};

const slice = createSlice({
  name: 'smokeScreen',
  initialState,
  reducers: {},
});

export default slice.reducer;
