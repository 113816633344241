import React, {ReactNode} from 'react';

import {Helmet} from 'react-helmet-async';
import MobileNav from '@landing/components/layout/header/MobileNav';
import SearchForm from '@landing/components/layout/header/SearchForm';
import {Link} from 'react-router-dom';
import Header from '@landing/components/layout/header/Header';
import Footer from '@landing/components/layout/footer/Footer';

interface Props {
  children: ReactNode;
  title?: string;
  meta?: ReactNode;
  showEdit?: boolean;

  [p: string]: any;
}

const AppPage = ({children, title = '', meta, ...other}: Readonly<Props>) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        {meta}
      </Helmet>

      <div className="page-wrapper">
        <Header />
        {children}
        <Footer />
      </div>
      <MobileNav />
      <SearchForm />
      <Link to="#" data-target="html" className="scroll-to-target scroll-to-top">
        <i className="icon-right-arrow"></i>
      </Link>
    </React.Fragment>
  );
};
export default AppPage;
