import React from 'react';
import $ from 'jquery';
import HeaderTop from '@landing/components/layout/header/HeaderTop';
import {Link} from 'react-router-dom';

const url = process.env.PUBLIC_URL;

function MainHeader() {
  const toggleMobileMenu = () => {
    $('.mobile-nav__toggler').on('click', function (e) {
      e.preventDefault();
      $('.mobile-nav__wrapper').toggleClass('expanded');
      $('body').toggleClass('locked');
    });
  };

  return (
    <header className="main-header-three">
      <HeaderTop />
      <nav className="main-menu main-menu-three">
        <div className="main-menu-three__wrapper">
          <div className="container">
            <div className="main-menu-three__wrapper-inner">
              <div className="main-menu-three__main-menu-box">
                <span onClick={toggleMobileMenu} className="mobile-nav__toggler">
                  <i className="fa fa-bars"></i>
                </span>
                <ul className="main-menu__list">
                  <li className="dropdown current">
                    <Link to={`${url}/`}>Home</Link>
                    <ul className="sub-menu">
                      <li>
                        <a href={`${url}/welcome`}>Overview of Taraba State</a>
                      </li>
                      <li>
                        <a href={`${url}/welcome`}>Governor’s Welcome</a>
                      </li>
                      <li>
                        <a href={`${url}/`}>State Vision and Mission</a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href={`${url}`}>Government</a>
                    <ul className="sub-menu">
                      <li>
                        <a href={url}>Executive Arm</a>
                        <ul className="sub-menu">
                          <li>
                            <a href={url}>Office of the Governor</a>
                          </li>
                          <li>
                            <a href={url}>Deputy Governor’s Office</a>
                          </li>
                          <li>
                            <a href={url}>Executive Council</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href={url}>Legislative Arm</a>
                        <ul className="sub-menu">
                          <li>
                            <a href={url}>Taraba House of Assembly</a>
                          </li>
                          <li>
                            <a href={url}>Committees and Bills</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href={url}>Judiciary</a>
                        <ul className="sub-menu">
                          <li>
                            <a href={url}>Courts and Justice System</a>
                          </li>
                          <li>
                            <a href={url}>Legal Resources</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href={url}>Ministries, Departments, and Agencies (MDAs)</a>
                        <ul className="sub-menu">
                          <li>
                            <a href={url}>Ministry of Health</a>
                          </li>
                          <li>
                            <a href={url}>Ministry of Education</a>
                          </li>
                          <li>
                            <a href={url}>Other MDAs</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">
                      Finances
                    </a>
                    {/*<ul className="sub-menu">*/}
                    {/*  <li>*/}
                    {/*    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">*/}
                    {/*      Taraba State MFBEP*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">*/}
                    {/*      Fiscal Reports*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">*/}
                    {/*      Annual Budget*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">*/}
                    {/*      Procurement and Revenue*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">*/}
                    {/*      Tax Policies*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://mfbep.tr.gov.ng/" target="_blank" rel="noreferrer">*/}
                    {/*      Citizens Budget Review*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*</ul>*/}
                  </li>
                  <li className="dropdown">
                    <a href="https://tarabastategov.cloud" target="_blank" rel="noreferrer">
                      Development Programmes
                    </a>
                    {/*<ul className="sub-menu">*/}
                    {/*  <li>*/}
                    {/*    <a href="https://tarabastategov.cloud" target="_blank" rel="noreferrer">*/}
                    {/*      SABER Programme (Education)*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://tarabastategov.cloud" target="_blank" rel="noreferrer">*/}
                    {/*      SFTAS Programme (Financial Transparency)*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://tarabastategov.cloud" target="_blank" rel="noreferrer">*/}
                    {/*      Rural Development Initiatives*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://tarabastategov.cloud" target="_blank" rel="noreferrer">*/}
                    {/*      Healthcare Initiatives*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="https://tarabastategov.cloud" target="_blank" rel="noreferrer">*/}
                    {/*      Agriculture and Food Security*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*</ul>*/}
                  </li>
                  <li className="dropdown">
                    <a href={url}>Explore Taraba</a>
                    <ul className="sub-menu">
                      <li>
                        <a href={url}>History of Taraba</a>
                      </li>
                      <li>
                        <a href={url}>Culture and Heritage</a>
                      </li>
                      <li>
                        <a href={url}>Tourism and Landmarks</a>
                      </li>
                      <li>
                        <a href={url}>Investment Opportunities</a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href={url}>News & Updates</a>
                    <ul className="sub-menu">
                      <li>
                        <a href={`${url}/press-releases`}>Press Releases</a>
                      </li>
                      <li>
                        <a href={url}>Official Statements</a>
                      </li>
                      <li>
                        <a href={url}>Project Announcements</a>
                      </li>
                      <li>
                        <a href={`${url}/gallery`}>Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href={url}>Public Services</a>
                    <ul className="sub-menu">
                      <li>
                        <a href={url}>Health Services</a>
                      </li>
                      <li>
                        <a href={url}>Education Resources</a>
                      </li>
                      <li>
                        <a href={url}>Business Registration</a>
                      </li>
                      <li>
                        <a href={url}>Public Safety Alerts</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href={url}>Contact</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="https://tarabastategov.online/upload/" target="_blank" rel="noreferrer">
                          Office Addresses
                        </a>
                      </li>
                      <li>
                        <a href="https://tarabastategov.online/upload/" target="_blank" rel="noreferrer">
                          Feedback Form
                        </a>
                      </li>
                      <li>
                        <a href="https://tarabastategov.online/upload/" target="_blank" rel="noreferrer">
                          Business Registration
                        </a>
                      </li>
                      <li>
                        <a href="https://tarabastategov.online/upload/" target="_blank" rel="noreferrer">
                          Social Media
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default MainHeader;
