import axios from 'axios';
import {AxiosClientOptionType, EnvType} from 'app-core';
import env from '@core/config/env';

export default function axiosClient(options?: AxiosClientOptionType) {
  const envType = process.env.NODE_ENV as EnvType;
  axios.defaults.baseURL = options?.baseURL ?? env[envType].api.baseURL;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.defaults.headers.put['Accept'] = 'application/json';
  axios.defaults.headers.put['Content-Type'] = 'application/json';

  axios.defaults.headers.get['Accept'] = 'application/json';
  axios.defaults.headers.delete['Accept'] = 'application/json';

  if (options?.token) {
    const token = localStorage.getItem('');

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return axios;
  }

  if (options?.axios) {
    return options.axios;
  }

  return axios;
}
