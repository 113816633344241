import React from 'react';

function HeaderSticky() {
  return (
    <div className="stricky-header stricked-menu main-menu main-menu-three">
      <div className="sticky-header__content"></div>
    </div>
  );
}

export default HeaderSticky;
