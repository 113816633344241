import React from 'react';
import {Link} from 'react-router-dom';

const url = process.env.PUBLIC_URL;

function HeaderTop() {
  return (
    <div className="main-header-three__top">
      <div className="container">
        <div className="main-header-three__top-inner">
          <div className="main-header-three__logo">
            <Link to={`${url}/`}>
              <img src={require('@assets/images/logo-1.png')} alt="" />
            </Link>
          </div>
          <div className="main-header-three__content-box">
            <ul className="list-unstyled main-header-three__contact-list">
              <li>
                <div className="icon">
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="text">
                  <p>
                    <a href="mailto:needhelp@company.com">info@tarabastate.gov.ng</a>
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <i className="fas fa-clock"></i>
                </div>
                <div className="text">
                  <p>Open hours: Mon - Fri 8.00 am - 6.00 pm</p>
                </div>
              </li>
            </ul>
            <div className="main-header-three__search-lan-switcher-btn">
              <div className="main-header-three__search-box">
                <Link to="#" className="main-header-three__search search-toggler icon-magnifying-glass"></Link>
              </div>
              <div className="main-header-three__btn-box">
                <a href={url} className="thm-btn main-header-three__btn">
                  FAQ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
